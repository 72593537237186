import Artikel1 from '../Image/artikel1.jpg';
import Artikel2 from '../Image/artikel2.jpg';
import Artikel3 from '../Image/artikel3.jpg';
import Artikel4 from '../Image/artikel4.webp';
import Artikel5 from '../Image/artikel5.webp';
import Artikel6 from '../Image/artikel6.jpg'

const dataArtikel = [
    {
        id: {
        "image": Artikel1,
        "nama" : "Kerupuk Rengginang Bintang dalam Seblak Pedas yang Menggigit",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://www.sukabumiupdate.com/produk/91803/seblak-dan-rengginang-intip-ragam-inovasi-kuliner-dari-ikan-di-jawa-barat#google_vignette'
        },
        en: {
        "image": Artikel1,
        "nama" : "Rengginang Bintang Crackers in Biting Spicy Seblak",
        "sub_nama": "Indonesian Cracker Center / 10 June 2024",
        "link": 'https://www.sukabumiupdate.com/produk/91803/seblak-dan-rengginang-intip-ragam-inovasi-kuliner-dari-ikan-di-jawa-barat#google_vignette'
        },
        ar: {
        "image": Artikel1,
        "nama" : "مقرمشات رينجينانج بينتانج في بايتنج سبايسي سيبلاك",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024",
        "link": 'https://www.sukabumiupdate.com/produk/91803/seblak-dan-rengginang-intip-ragam-inovasi-kuliner-dari-ikan-di-jawa-barat#google_vignette' 
        },
        zh: {
        "image": Artikel1,
        "nama" : "辛辣 Seblak 中的 Rengginang Bintang 饼干",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://www.sukabumiupdate.com/produk/91803/seblak-dan-rengginang-intip-ragam-inovasi-kuliner-dari-ikan-di-jawa-barat#google_vignette'
        }
    },
    {
        id: {
        "image": Artikel2,
        "nama" : "Seblak dan Kerupuk Bawang, Pernikahan Harmonis Rasa dan Aroma",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://cookpad.com/id/resep/11148715-seblak-kerupuk-bawang'
        },
        en: {
        "image": Artikel2,
        "nama" : "Seblak and Onion Crackers, A Harmonious Marriage of Taste and Aroma",
        "sub_nama": "Indonesian Cracker Center / 04 June 2024",
        "link": 'https://cookpad.com/id/resep/11148715-seblak-kerupuk-bawang'
        },
        ar: {
        "image": Artikel2,
        "nama" : "سيبلاك ومقرمشات البصل، زواج متناغم بين الطعم والرائحة",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024",
        "link": 'https://cookpad.com/id/resep/11148715-seblak-kerupuk-bawang' 
        },
        zh: {
        "image": Artikel2,
        "nama" : "印度尼西亚饼干企业家的成功故事",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://cookpad.com/id/resep/11148715-seblak-kerupuk-bawang'
        }
    },
    {
        id: {
        "image": Artikel3,
        "nama" : "Kerupuk Sebagai Bahan Multifungsi Kreativitas Tanpa Batas dalam Seblak",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://www.halodoc.com/artikel/resep-kerupuk-seblak-coet-pedas-nikmat-dan-bikin-nagih'
        },
        en: {
        "image": Artikel3,
        "nama" : "Crackers as a Multifunctional Ingredient Unlimited Creativity in Seblak",
        "sub_nama": "Indonesian Cracker Center / 10 June 2024",
        "link": 'https://www.halodoc.com/artikel/resep-kerupuk-seblak-coet-pedas-nikmat-dan-bikin-nagih'
        },
        ar: {
        "image": Artikel3,
        "nama" : "المفرقعات كمكون متعدد الوظائف وإبداع غير محدود في سبلاك",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024,",
        "link": 'https://www.halodoc.com/artikel/resep-kerupuk-seblak-coet-pedas-nikmat-dan-bikin-nagih' 
        },
        zh: {
        "image": Artikel3,
        "nama" : "饼干作为一种多功能成分 Seblak 的无限创造力",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://www.halodoc.com/artikel/resep-kerupuk-seblak-coet-pedas-nikmat-dan-bikin-nagih'
        }
    },
    {
        id: {
        "image": Artikel4,
        "nama" : "Manfaat Kerupuk dalam Seblak Lezat dan Bergizi?",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://bandung.viva.co.id/kuliner/21111-bukan-jajanan-biasa-ini-7-manfaat-seblak-bagi-kesehatan?page=all'
        },
        en: {
        "image": Artikel4,
        "nama" : "Benefits of Crackers in Delicious and Nutritious Seblak?",
        "sub_nama": "Indonesian Cracker Center / 10 June 2024",
        "link": 'https://bandung.viva.co.id/kuliner/21111-bukan-jajanan-biasa-ini-7-manfaat-seblak-bagi-kesehatan?page=all'
        },
        ar: {
        "image": Artikel4,
        "nama" : "فوائد البسكويت في السبلاك اللذيذ والمغذي؟",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024",
        "link": 'https://bandung.viva.co.id/kuliner/21111-bukan-jajanan-biasa-ini-7-manfaat-seblak-bagi-kesehatan?page=all' 
        },
        zh: {
        "image": Artikel4,
        "nama" : "美味又营养的 Seblak 饼干的好处？",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://bandung.viva.co.id/kuliner/21111-bukan-jajanan-biasa-ini-7-manfaat-seblak-bagi-kesehatan?page=all'
        }
    },
    {
        id: {
        "image": Artikel5,
        "nama" : "Pencarian Tekstur Ideal, Memahami Peran Kerupuk dalam Seblak",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://prianganinsider.pikiran-rakyat.com/ragam/pr-3837945014/rahasia-bumbu-seblak-yang-enak-bahan-berkualitas-kuncinya?page=all'
        },
        en: {
        "image": Artikel5,
        "nama" : "Search for Ideal Texture, Understanding the Role of Crackers in Seblak",
        "sub_nama": "Indonesian Cracker Center / 10 June 2024",
        "link": 'https://prianganinsider.pikiran-rakyat.com/ragam/pr-3837945014/rahasia-bumbu-seblak-yang-enak-bahan-berkualitas-kuncinya?page=all'
        },
        ar: {
        "image": Artikel5,
        "nama" : "ابحث عن الملمس المثالي، وفهم دور المفرقعات في السبلاك",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024",
        "link": 'https://prianganinsider.pikiran-rakyat.com/ragam/pr-3837945014/rahasia-bumbu-seblak-yang-enak-bahan-berkualitas-kuncinya?page=all' 
        },
        zh: {
        "image": Artikel5,
        "nama" : "寻找理想的质地，了解饼干在 Seblak 中的作用",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://prianganinsider.pikiran-rakyat.com/ragam/pr-3837945014/rahasia-bumbu-seblak-yang-enak-bahan-berkualitas-kuncinya?page=all'
        }
    },
    {
        id: {
        "image": Artikel6,
        "nama" : "Seblak Seafood, Sensasi Laut dengan Kerupuk sebagai Pendamping Utama",
        "sub_nama": "Pusat Kerupuk Indonesia / 10 Juni 2024",
        "link": 'https://cookpad.com/id/cari/seblak%20seafood%20kerupuk'
        },
        en: {
        "image": Artikel6,
        "nama" : "Seblak Seafood, Sea Sensation with Crackers as the Main Accompaniment",
        "sub_nama": "Indonesian Cracker Center / 10 June 2024",
        "link": 'https://cookpad.com/id/cari/seblak%20seafood%20kerupuk'
        },
        ar: {
        "image": Artikel6,
        "nama" : "المأكولات البحرية سيبلاك، إحساس البحر مع البسكويت كمرافقة رئيسية",
        "sub_nama": "مركز التكسير الإندونيسي / 10 يونيو 2024",
        "link": 'https://cookpad.com/id/cari/seblak%20seafood%20kerupuk' 
        },
        zh: {
        "image": Artikel6,
        "nama" : "Seblak海鲜，以饼干为主的海洋感觉",
        "sub_nama": "印度尼西亚饼干中心 / 2024 年 6 月 10 日",
        "link": 'https://cookpad.com/id/cari/seblak%20seafood%20kerupuk'
        }
    },
]

export default dataArtikel